<template>
  <div>
    <avatar
      background="#4EA4F4"
      v-if="slug == 'analytics'"
    >
      <Icon
        icon-name="analytic"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#13B56A"
      v-if="slug == 'approval'"
    >
      <Icon
        icon-name="approvalHierachy"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#13B56A"
      v-if="slug == 'overtime'"
    >
      <Icon
        icon-name="overtime_icon"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#A4160D"
      v-if="slug == 'payments'"
    >
      <Icon
        icon-name="payment"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#333333"
      v-if="slug == 'documents'"
    >
      <Icon
        icon-name="document"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#F4D5B0"
      v-if="slug == 'employeeManagement'"
    >
      <Icon
        icon-name="employees"
        size="s"
        class="text-flame"
      />
    </avatar>
    <avatar
      background="#F15A29"
      v-if="slug == 'orgAndPeople'"
    >
      <Icon
        icon-name="organisation"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#F15A29"
      v-if="slug == 'compensationPlanning'"
    >
      <Icon
        icon-name="icon-compensation"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#878E99"
      v-if="slug == 'location'"
    >
      <Icon
        icon-name="locationAndTax"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#EEEEEE"
      v-if="slug == 'performanceManagement'"
    >
      <Icon
        icon-name="performance"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#1055A4"
      v-if="slug == 'payroll'"
    >
      <Icon
        icon-name="payroll"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#1055A4"
      v-if="slug == 'timeOff'"
    >
      <Icon
        icon-name="icon-sand-clock"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#E99323"
      v-if="slug == 'advanceAndLoan'"
    >
      <Icon
        icon-name="benefits"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#4EA4F4"
      v-if="slug == 'settings'"
    >
      <Icon
        icon-name="settings"
        size="s"
        class="text-white"
      />
    </avatar>
    <avatar
      background="#878E99"
      v-if="slug == 'timeAttendance'"
    >
      <Icon
        icon-name="icon-clockin"
        size="s"
        class="text-white"
      />
    </avatar>
  </div>
</template>

<script>
import Avatar from './Avatar';

export default {
  components: { Avatar },
  props: {
    slug: {
      type: String,
      default: '',
    },
  },

};
</script>

<style>

</style>
