<template>
  <div
    class="avatar"
    :style="`background:${background}`"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.avatar{
    width: 40px;
    height: 40px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
}
</style>
